import React, { useCallback } from 'react'
import { ReactNode, useRef, useState } from 'react'
import {
    Control,
    FieldValues,
    RegisterOptions,
    useController,
} from 'react-hook-form'
import useOnclickOutside from 'react-cool-onclickoutside'
import cn from 'classnames'
import { RxCaretDown, RxCaretUp } from 'react-icons/rx'
import { MdOutlineDone } from 'react-icons/md'
import { RiCloseFill } from 'react-icons/ri'
import { AiOutlineClose } from 'react-icons/ai'
import cx from 'classnames'

export const classes: {
    base: string
    input: {
        base: string
        error: string
        placeholder: string
        icon: string
        close: string
    }
    list: { base: string; item: string; active: string }
    disabled: string
} = {
    base: 'relative border min-h-[36px] border-theme border-gray-300 rounded-md p-1 pr-2 flex gap-1',
    input: {
        base: 'block b-0 outline-0 h-auto rounded-md h-6 w-full transition  ease-in-out placeholder-current px-2 font-[300]',
        error: '!border-[#ea5455]',
        placeholder: 'text-gray-400',
        icon: 'items-center text-gray-300 flex gap-1 ',
        close: 'cursor-pointer',
    },
    list: {
        base: `absolute top-[calc(100%_+_5px)] max-w-[200px] p-0.5 overflow-auto rounded border-solid border border-theme z-10 bg-white right-0 min-w-full  wrapper-light max-h-[200px]`,
        item: '!border-[#ea5455] pointer px-3 py-2 flex justify-between transition gap-4 cursor-pointer rounded hover:text-blue-500',
        active: 'bg-primary pointer-events-none text-blue-500',
    },
    disabled: 'opacity-80 pointer-events-none',
}

export type SelectProps = React.InputHTMLAttributes<HTMLInputElement> & {
    control: Control<FieldValues, string>
    name: string
    options: Option[]
    rules?: RegisterOptions
    autoComplete?: boolean
    icon?: ReactNode
    isClose?: boolean
    placeholder?: string
    topPosition?: boolean
}

export type Option = {
    value: string
    name: string
}

const SelectMultiple = ({
    control,
    name,
    rules,
    options,
    disabled,
    isClose,
    placeholder,
    topPosition,
}: SelectProps) => {
    const [isSelectOpen, setSelectOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const ref = useRef<HTMLDivElement>(null)
    const closeOptions = () => {
        setSelectOpen(false)
        setSearchValue('')
    }
    useOnclickOutside(closeOptions, { refs: [ref] })
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name,
        rules,
    })

    const value = field.value || []

    const optionsList = options.filter((option) =>
        searchValue
            ? option.name.toUpperCase().includes(searchValue.toUpperCase())
            : true
    )

    return (
        <div
            className={cn(classes.base, {
                [classes.disabled]: disabled,
                // 'p-2': value.length,
            })}
            ref={ref}
            onClick={() => {
                if (!disabled) setSelectOpen((prevValue) => !prevValue)
            }}
        >
            {value.length ? (
                <div className="flex flex-wrap gap-2 max-w-[400px]">
                    {value.map((val: Option) => (
                        <div
                            className="flex  border border-blue-100 gap-2 items-center px-1 rounded bg-blue-50 "
                            key={val.value}
                        >
                            <span>{val.name}</span>
                            <div
                                className="cursor-pointer text-red-300 bg-red-50 text-xs border border-red-300 rounded-[2px]"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    const values = value.filter(
                                        (option: Option) =>
                                            option.value !== val.value
                                    )
                                    field.onChange(values)
                                }}
                            >
                                <AiOutlineClose />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div
                        className={cn(classes.input.base, {
                            [classes.input.error]: error,
                            [classes.input.placeholder]: placeholder,
                        })}
                    >
                        {placeholder}
                    </div>
                </>
            )}

            {!disabled && (
                <div className={classes.input.icon}>
                    {isClose && field.value && (
                        <div
                            onClick={() => field.onChange('')}
                            className={classes.input.close}
                        >
                            <RiCloseFill color={error && '#ea5455'} />
                        </div>
                    )}

                    {isSelectOpen ? (
                        <RxCaretUp color={error && '#ea5455'} size={18} />
                    ) : (
                        <RxCaretDown color={error && '#ea5455'} size={18} />
                    )}
                </div>
            )}

            {isSelectOpen && (
                <ul
                    className={cx(classes.list.base, {
                        'bottom-[calc(100%_+_5px)] top-auto': topPosition,
                    })}
                >
                    {optionsList.map((option, index) => (
                        <li
                            className={cn(classes.list.item, {
                                [classes.list.active]: !!value.find(
                                    (o: Option) => o.value === option.value
                                ),
                            })}
                            onClick={(e) => {
                                e.stopPropagation()
                                setSelectOpen(false)
                                setSearchValue('')
                                if (
                                    !value.find(
                                        (o: Option) => o.value === option.value
                                    )
                                ) {
                                    field.onChange([...value, option])
                                }
                            }}
                            key={index}
                        >
                            {option.name}
                            {value === option.name && <MdOutlineDone />}
                        </li>
                    ))}
                    {!optionsList.length && (
                        <div className="py-3">
                            <span className="text-center">Geen opties</span>
                        </div>
                    )}
                </ul>
            )}
        </div>
    )
}

export default SelectMultiple
