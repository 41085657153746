import cn from 'classnames'
import React, { ReactNode, useEffect } from 'react'
import styles from './Spinner.module.scss'

export interface LoadingProps {
    isLoading?: boolean
    children: ReactNode
    className: string
    id?: string
}

export function Spinner({ isLoading, children, className, id }: LoadingProps) {
    return (
        <>
            <div
                id={id}
                className={cn(className, {
                    [styles.loading__children__loadCustome]: isLoading,
                })}
            >
                {children}
            </div>
        </>
    )
}

export default React.memo(Spinner)
