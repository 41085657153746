import React, { useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import PdfCard from './PdfCard'
import { ChatItem, Document, Metadata, QuoteObject, ZoomItem } from '../types'
import { DragSizing } from 'react-drag-sizing'
import cx from 'classnames'
import {
    getDate,
    getViewerLibraryPath,
    highlightTextInPdf,
} from '../utils/helpers'
import { IoMdClose } from 'react-icons/io'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import { VscFeedback } from 'react-icons/vsc'
import ModalWrapper from './ModalWrapper/ModalWrapper'
import FeedbackForm from './FeedbackForm'
import { FieldValues, useForm } from 'react-hook-form'
import FilterWrapper from './FilterWrapper/FilterWrapper'
import SelectMultiple from './SelectMultiple/SelectMultiple'
import { LuFilter, LuFilterX } from 'react-icons/lu'
import { AiOutlineClear } from 'react-icons/ai'
import ReactMarkdown from 'react-markdown'

interface SearchContainerProps {
    chatItem: ChatItem
    clientHeight: number
    filters?: Metadata
    chatMessagesListLength: number
}

const filterDocuments = (
    documents: Document[],
    filterValues: Record<string, { value: string | number }[]>
) => {
    // Check if all filters are empty
    const isAllFiltersEmpty = Object.values(filterValues).some((values) =>
        Array.isArray(values) ? values.length : values
    )

    // If all filters are empty, return all documents
    if (!isAllFiltersEmpty) {
        return documents
    }

    return documents.filter((doc: Document) => {
        return Object.entries(filterValues).every(([key, values]) => {
            if (!values || !values.length) {
                return true
            }
            const docValue = (doc as any)[key]

            return (values || []).some((val) =>
                Array.isArray(docValue)
                    ? docValue.includes(val.value)
                    : val.value == docValue
            )
        })
    })
}

export default function SearchContainer({
    chatItem,
    clientHeight,
    filters,
    chatMessagesListLength,
}: SearchContainerProps) {
    const [zoomList, setZoomList] = useState<ZoomItem[]>([])
    const [isResizing, setIsResizing] = useState(false)
    const [tipsIsOpen, setTipsIsOpen] = useState(false)
    const [feedbackIsOpen, setFeedbackIsOpen] = useState(false)
    const [filterIsOpen, setFilterIsOpen] = useState(false)

    const [showItems, setShowItems] = useState(20)
    // const defaultPath = '/pdf/test.pdf'

    const filtersList = Object.entries(filters?.properties || {}).map(
        (prop) => ({
            name: prop[0],
            options: prop[1].anyOf[0].enum.map((v) => ({ name: v, value: v })),
        })
    )

    const { control, reset, watch } = useForm<FieldValues>({
        mode: 'onChange',
    })

    const filterValues = watch()

    const docs = chatItem?.message_data?.documents || []
    const documents = filterDocuments(docs, filterValues)

    const documentsSliced = documents.slice(0, showItems)
    const handleQuoteClick = (quoteObject: QuoteObject) => {
        const findFile = documents.find(
            (doc) => doc.title === quoteObject.document_title
        )
        setTipsIsOpen(false)
        const pdfPath = findFile?.highlighted_pdf_path || findFile?.pdf_path
        const viewerUrl = getViewerLibraryPath(pdfPath)
        const id = (quoteObject.document_title || '').split(' ').join('')
        const isFileOpen = zoomList.find(
            (item) => item.id === quoteObject.document_title.split(' ').join('')
        )
        if (!isFileOpen) {
            setZoomList([
                {
                    id,
                    viewerUrl,
                },
            ])
            Promise.resolve().then(() =>
                highlightTextInPdf(quoteObject.actual_quote_text.trim(), id)
            )
        } else {
            highlightTextInPdf(quoteObject.actual_quote_text.trim(), id)
        }
    }

    const copyAssistantAnswer = () => {
        navigator.clipboard.writeText(chatItem.message_text || '')
    }

    return (
        <>
            <div
                className="flex bg-white rounded-lg min-h-[600px] border border-gray-200 w-full"
                style={{
                    [zoomList.length ? 'height' : 'maxHeight']: clientHeight
                        ? clientHeight -
                          (chatMessagesListLength === 1 ? 166 : 109)
                        : 'auto',
                }}
            >
                <DragSizing
                    className="p-6 py-3 max-w-[45%] pr-4 min-w-[200px] w-full shrink-0 flex flex-col border-r border-gray-200 "
                    onStart={() => setIsResizing(true)}
                    onEnd={() => setIsResizing(false)}
                    border="right"
                >
                    <div className="overflow-auto w-full  pr-2">
                        <div className="flex items-center justify-between gap-2 border-b border-gray-200 pb-3 mb-3">
                            <span className="text-gray-400 text-[16px]">
                                {getDate(chatItem?.created_at)}
                            </span>
                            <div className="flex gap-2">
                                <button
                                    onClick={copyAssistantAnswer}
                                    className="text-xl border shrink-0 h-[35px] w-[35px] flex items-center justify-center border-gray-300 text-gray-500 rounded-md pt-[2px] transition-all hover:bg-blue-50"
                                    title="Copy assistant's answer"
                                >
                                    <FaRegCopy />
                                </button>
                                <button
                                    onClick={() => setFeedbackIsOpen(true)}
                                    className="text-xl border shrink-0 h-[35px] w-[35px] flex items-center justify-center border-gray-300 text-gray-500 rounded-md pt-[2px] transition-all hover:bg-blue-50"
                                >
                                    <VscFeedback />
                                </button>
                            </div>
                        </div>

                        <div className="mb-3">
                            <span className="font-bold uppercase">user</span>:{' '}
                            {chatItem.user_message_text}
                        </div>

                        <div className="relative">
                            <span className="font-bold uppercase">
                                {chatItem.texter_role}
                            </span>
                            :{' '}
                            <ReactMarkdown className="[&_ul]:ml-3 *:!border-0">
                                {chatItem.message_text || 'Loading...'}
                            </ReactMarkdown>
                        </div>

                        {chatItem.message_data.quotes_info &&
                            chatItem.message_data.quotes_info.length > 0 && (
                                <>
                                    <h3 className="font-bold uppercase mt-3">
                                        Relevant Quotes:
                                    </h3>
                                    <div className="quotes mt-2.5 flex-grow ">
                                        <ul className="list-none pl-0 flex flex-col gap-3">
                                            {chatItem.message_data.quotes_info?.map(
                                                (quoteObject, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() =>
                                                            handleQuoteClick(
                                                                quoteObject
                                                            )
                                                        }
                                                        className="cursor-pointer transition-colors duration-300 ease-in-out p-2 rounded-md hover:bg-blue-50"
                                                    >
                                                        <blockquote className="m-0 pl-4 border-l-4 border-gray-300 italic">
                                                            {
                                                                quoteObject.actual_quote_text
                                                            }
                                                        </blockquote>
                                                        <cite className="block mt-2 text-sm text-gray-600">
                                                            Source:{' '}
                                                            {
                                                                quoteObject.document_title
                                                            }
                                                        </cite>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </>
                            )}
                    </div>
                </DragSizing>

                <div className="p-6 py-3 flex flex-col pl-6 overflow-hidden flex-grow">
                    {!zoomList.length && (
                        <div className="flex items-start justify-between gap-2 border-b border-gray-200 pb-3 mb-3">
                            <div className="flex gap-2">
                                <button
                                    onClick={() =>
                                        setFilterIsOpen(!filterIsOpen)
                                    }
                                    className="text-xl border shrink-0 h-[36px] w-[36px] flex items-center justify-center border-gray-300 text-gray-500 rounded-md pt-[2px] transition-all hover:bg-blue-50"
                                >
                                    {filterIsOpen ? (
                                        <LuFilterX />
                                    ) : (
                                        <LuFilter />
                                    )}
                                </button>
                                <button
                                    onClick={() => reset({})}
                                    className="text-xl border shrink-0 h-[36px] w-[36px] flex items-center justify-center border-gray-300 text-gray-500 rounded-md pt-[2px] transition-all hover:bg-blue-50"
                                >
                                    <AiOutlineClear />
                                </button>
                            </div>

                            <FilterWrapper isOpen={filterIsOpen}>
                                {filtersList.map((filter) => (
                                    <SelectMultiple
                                        placeholder={filter.name
                                            .split('_')
                                            .join(' ')}
                                        control={control}
                                        name={filter.name}
                                        options={filter.options}
                                    />
                                ))}
                            </FilterWrapper>
                        </div>
                    )}
                    {!documentsSliced.length && (
                        <div className="flex-grow flex flex-col justify-center items-center pb-7">
                            <p className="text-gray-500">
                                No matching files were found for this request
                            </p>
                        </div>
                    )}
                    <div className="w-full pb-0 overflow-auto h-auto relative">
                        <div
                            className={cx('flex flex-wrap h-auto gap-3', {
                                '!flex-nowrap pb-3': !!zoomList.length,
                            })}
                        >
                            {!!documentsSliced.length && (
                                <>
                                    {documentsSliced?.map((doc, index) => (
                                        <PdfCard
                                            tipsIsOpen={tipsIsOpen}
                                            key={index}
                                            zoomList={zoomList}
                                            document={doc}
                                            onToggleExpand={() => {
                                                const pdfPath =
                                                    doc.highlighted_pdf_path ||
                                                    doc.pdf_path
                                                const viewerUrl =
                                                    getViewerLibraryPath(
                                                        pdfPath
                                                    )
                                                setTipsIsOpen(false)
                                                const id = (doc.title || '')
                                                    .split(' ')
                                                    .join('')
                                                setZoomList([
                                                    {
                                                        id,
                                                        viewerUrl,
                                                    },
                                                    ...(zoomList[0]
                                                        ? [zoomList[0]]
                                                        : []),
                                                ])
                                            }}
                                        />
                                    ))}

                                    {!zoomList.length &&
                                        documents.length > showItems && (
                                            <button
                                                onClick={() =>
                                                    setShowItems(showItems + 5)
                                                }
                                                className="border text-blue-500 w-full p-2 border-gray-200 sticky bg-blue-50 rounded-md "
                                            >
                                                show more
                                            </button>
                                        )}

                                    {!!zoomList.length && (
                                        <button
                                            onClick={() =>
                                                setTipsIsOpen(!tipsIsOpen)
                                            }
                                            className={cx(
                                                'border h-[56px] border-gray-300 sticky bg-white rounded flex flex-col justify-center text-gray-500 right-0',
                                                { '!h-[156px]': tipsIsOpen }
                                            )}
                                        >
                                            {tipsIsOpen ? (
                                                <>
                                                    {' '}
                                                    <FaLongArrowAltDown />
                                                    <FaLongArrowAltUp />
                                                </>
                                            ) : (
                                                <>
                                                    <FaLongArrowAltUp />
                                                    <FaLongArrowAltDown />
                                                </>
                                            )}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {!!zoomList.length && (
                        <div
                            className={cx('flex flex-grow overflow-auto', {
                                'opacity-30': isResizing,
                            })}
                        >
                            {zoomList.map((item, idx) => (
                                <div
                                    className="w-full h-full relative "
                                    key={idx}
                                >
                                    <button
                                        onClick={() => {
                                            const newZoomList = zoomList.filter(
                                                (zoomItem) =>
                                                    item.id !== zoomItem.id
                                            )
                                            setZoomList(newZoomList)
                                            if (!newZoomList.length) {
                                                setTipsIsOpen(true)
                                            }
                                        }}
                                        style={{
                                            backgroundColor: '#38383d',
                                        }}
                                        className="w-7 h-7 text-white flex items-center justify-center absolute top-1 left-1"
                                    >
                                        <IoMdClose />
                                    </button>
                                    <div className="flex-grow w-full h-full border  overflow-hidden border-b-[5px] border-[#2a2a2e]">
                                        <iframe
                                            id={item.id}
                                            className="w-full h-full"
                                            src={item.viewerUrl}
                                        ></iframe>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {feedbackIsOpen && (
                    <ModalWrapper>
                        <div className="relative max-w-[500px] w-full flex flex-col items-center bg-white rounded-md p-10">
                            <button
                                onClick={() => setFeedbackIsOpen(false)}
                                className="rounded-full flex border border-gray-300 h-6 w-6 items-center justify-center absolute right-3 top-3"
                            >
                                <IoMdClose />
                            </button>
                            <div className="flex flex-col items-center justify-center w-full mb-5">
                                <h1 className="text-gray-700 font-bold  mb-3 text-3xl text-center">
                                    We'd love to hear from you!
                                </h1>
                                <p className="text-gray-500 mb-3 text-center">
                                    Your feedback helps us enhance the app
                                    experience. Please take a moment to share
                                    your thoughts.
                                </p>
                            </div>
                            <FeedbackForm
                                chatItem={chatItem}
                                handleClose={() => setFeedbackIsOpen(false)}
                            />
                        </div>
                    </ModalWrapper>
                )}
            </div>
        </>
    )
}
