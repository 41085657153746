import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react'
import {
    Control,
    FieldValues,
    RegisterOptions,
    useController,
} from 'react-hook-form'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { PiWarningCircle } from 'react-icons/pi'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import cn from 'classnames'

export const classes: {
    base: string
    disabled: string
    error: string
    valid: string
    icons: string
    iconSpace: string
} = {
    base: 'flex-grow p-2 border border-gray-300 rounded-md min-h-[40px] max-h-[120px] resize-y font-inherit placeholder:font-[300]',
    disabled:
        'opacity-80 cursor-not-allowed pointer-events-none disabled-input-bg',
    error: '!border-[#ea5455]',
    valid: '!border-[#28c76f]',
    icons: 'absolute flex gap-0.5 right-2 top-[50%] translate-y-[-50%]',
    iconSpace: 'pr-7',
}

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    control: Control<FieldValues, string>
    name: string
    label?: string
    callBack?: () => void
    className?: string
    rules?: RegisterOptions
    icon?: ReactNode
    symbol?: string
    type?: string
    patternRule?: RegExp
    isFocus?: boolean
    isDebounce?: boolean
    disabled?: boolean
}

const Input = ({
    control,
    callBack,
    name,
    rules,
    defaultValue,
    className,
    patternRule,
    type,
    icon,
    isFocus,
    isDebounce,
    disabled,
    ...rest
}: TextInputProps) => {
    const [isPassword, setIsPassword] = useState(true)
    const {
        field,
        fieldState: { error, invalid, isDirty },
    } = useController({
        control,
        name,
        rules,
        defaultValue,
    })
    const [value, setValue] = useState(field.value)

    const debouncedChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (patternRule && value !== '' && !value.match(patternRule)) {
            e.preventDefault()
        } else {
            field.onChange(e)
        }
    }

    return (
        <div className="flex flex-col ">
            <div className="flex w-full relative">
                {icon && <div>{icon}</div>}
                <input
                    className={cn(
                        classes.base,
                        {
                            [classes.error]: error,
                            [classes.disabled]: disabled,
                            [classes.iconSpace]:
                                type === 'password' ||
                                (error && type !== 'password') ||
                                (!invalid &&
                                    field.value &&
                                    isFocus &&
                                    type !== 'password' &&
                                    isDirty),
                            [classes.valid]:
                                !invalid && field.value && isFocus && isDirty,
                        },
                        className
                    )}
                    name={field.name}
                    onChange={debouncedChangeHandler}
                    onBlur={field.onBlur}
                    value={!isDebounce ? field.value : value}
                    id={name}
                    type={isPassword ? type : 'text'}
                    disabled={disabled}
                    {...rest}
                />
                <div className={classes.icons}>
                    {type === 'password' && (
                        <div onClick={() => setIsPassword(!isPassword)}>
                            {isPassword && <LuEye size={18} />}
                            {!isPassword && <LuEyeOff size={18} />}
                        </div>
                    )}
                    {error && type !== 'password' && (
                        <PiWarningCircle size={18} color="#ea5455" />
                    )}
                    {!invalid &&
                        field.value &&
                        isFocus &&
                        type !== 'password' &&
                        isDirty && (
                            <IoCheckmarkCircleOutline
                                size={18}
                                color="#28c76f"
                            />
                        )}
                </div>
            </div>
            {!!error?.message && (
                <p className="mt-1 text-left text-xs leading-none text-red-600">
                    {error?.message}
                </p>
            )}
        </div>
    )
}

export default Input
