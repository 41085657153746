import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Providers from './utils/providers'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Login from './pages/Login'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Providers>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
    </Providers>
)
