import { getToken, logout } from '../services/authService'

export type RequestProps = {
    url: string
    method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
    data?: object | URLSearchParams
    isAuthorized?: boolean
    customHeaders?: Record<string, string>
}

export type MakeRequestReturn<T> = {
    data?: T
    errors?: string | null
}

const API_URL = process.env.REACT_APP_API_URL || ''

const makeRequest = async <T = undefined,>({
    url,
    method = 'GET',
    data,
    isAuthorized,
    customHeaders = {},
}: RequestProps): Promise<MakeRequestReturn<T>> => {
    try {
        const token = getToken()
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            ...customHeaders,
        }

        if (!isAuthorized && token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        const body =
            data instanceof URLSearchParams ? data : JSON.stringify(data)

        const response = await fetch(API_URL + url, {
            method,
            headers,
            body: method !== 'GET' ? body : undefined,
        })

        const responseData = await response.json()

        if (!response.ok) {
            if (response.status === 401 && !isAuthorized) {
                window.location.href = '/login'
                logout()
            }
            throw new Error(responseData.detail || 'Request failed')
        }

        return {
            data: responseData as T,
        }
    } catch (error: any) {
        return {
            errors: error.message || 'An unknown error occurred',
        }
    }
}

export default makeRequest
