import React, { useState } from 'react'

import { RiChatNewLine } from 'react-icons/ri'
import { FieldValues, useForm } from 'react-hook-form'
import Input from './Input'
import { EMAIL_REGEXP, PASSWORD_REGEXP, setAccessToken } from '../utils/helpers'
import makeRequest from '../network/make-request'
import TextArea from './TextArea'
import { ChatItem } from '../types'

interface CreateChatFormProps {
    handleClose: () => void
    chatItem: ChatItem
}

export default function FeedbackForm({
    handleClose,
    chatItem,
}: CreateChatFormProps) {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const { control, reset, handleSubmit } = useForm<FieldValues>({
        mode: 'onChange',
    })

    const messageSubmit = async (values: FieldValues) => {
        setLoading(true)
        try {
            const payload = await makeRequest<{
                access_token: string
            }>({
                url: '/api/feedback/',
                method: 'POST',
                data: {
                    feedback_text: values.feedback_text,
                    message_id: chatItem.id,
                },
            })

            if (payload.errors) {
                setError('Something went wrong. Please try again later')
            }

            if (payload.data) {
                handleClose()
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <form
            onSubmit={handleSubmit(messageSubmit)}
            className="relative w-full"
        >
            <div className="mb-8">
                <TextArea
                    control={control}
                    name="feedback_text"
                    placeholder="message"
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required',
                        },
                    }}
                />
            </div>

            <button
                disabled={loading}
                type="submit"
                className="px-4 py-2 bg-blue-500 w-full text-white rounded-md transition-colors duration-200 hover:bg-blue-600 disabled:opacity-30"
            >
                Send
            </button>

            <p className="text-red-600 text-center mt-3">{error}</p>
        </form>
    )
}
