import React, { ReactNode } from 'react'
import { Collapse } from 'react-collapse'
import cx from 'classnames'

type BadgeProps = {
    isOpen: boolean
    isDirty?: boolean
    children: ReactNode
    className?: string
}

const FilterWrapper = ({ isOpen, children, className }: BadgeProps) => (
    <Collapse isOpened={isOpen}>
        <div
            className={cx(
                'flex gap-2 flex-wrap flex-grow items-start',
                className
            )}
        >
            {children}
        </div>
    </Collapse>
)

export default FilterWrapper
