import React, { ReactNode } from 'react'

type BadgeProps = {
    children: ReactNode
}

const ModalWrapper = ({ children }: BadgeProps) => (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-60 z-20 p-10 flex items-center justify-center">
        {children}
    </div>
)

export default ModalWrapper
