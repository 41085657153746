import React, { useState } from 'react'
import { RiChatNewLine } from 'react-icons/ri'
import { IoLeafOutline } from 'react-icons/io5'
import { IoEarthOutline } from 'react-icons/io5'
import { LiaHandshakeSolid } from 'react-icons/lia'
import { TbReportMoney } from 'react-icons/tb'
import TextareaAutosize from 'react-textarea-autosize'
interface CreateChatFormProps {
    handleCreateChat: (value: string) => void
}
export default function CreateChatForm({
    handleCreateChat,
}: CreateChatFormProps) {
    const [inputValue, setInputValue] = useState('')

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                handleCreateChat(inputValue)
                // setInputValue('')
            }}
            className="relative w-full"
        >
            <div className="flex gap-3 mb-3">
                <div className="flex gap-3 flex-wrap flex-grow">
                    <TextareaAutosize
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault()
                                handleCreateChat(inputValue)
                            }
                        }}
                        placeholder="Type your message..."
                        className="min-w-[150px] flex-grow  p-2 border border-gray-300 rounded-md min-h-[40px] max-h-[120px] resize-y font-inherit placeholder:font-[300]"
                    />
                </div>
                <button
                    disabled={!inputValue}
                    type="submit"
                    className="border-gray-300 text-blue-500 rounded-md border w-10 bg-white transition-all flex-shrink-0 flex items-center justify-center cursor-pointer enabled:bg-blue-500 enabled:text-white enabled:border-blue-500 hover:bg-blue-50"
                >
                    <RiChatNewLine />
                </button>
            </div>
            <div className="flex gap-3">
                <button
                    type="button"
                    onClick={() =>
                        handleCreateChat(
                            "IUCN's position on arctic preservation"
                        )
                    }
                    className="border border-gray-300 transition-all rounded-md bg-white p-1 px-1 hover:bg-blue-50"
                >
                    <span className="mb-1 flex text-blue-500">
                        <IoEarthOutline />
                    </span>
                    <p className="text-xs text-gray-400 text-left">
                        IUCN's position on arctic preservation
                    </p>
                </button>
                <button
                    type="button"
                    onClick={() =>
                        handleCreateChat('Uniting global preservation efforts')
                    }
                    className="border border-gray-300 transition-all rounded-md bg-white p-1 px-1 hover:bg-blue-50"
                >
                    <span className="mb-1 flex text-blue-500">
                        <IoLeafOutline />
                    </span>
                    <p className="text-xs text-gray-400 text-left">
                        Uniting global preservation efforts
                    </p>
                </button>
                <button
                    type="button"
                    onClick={() =>
                        handleCreateChat(
                            'IConserving Arctic biodiversity together'
                        )
                    }
                    className="border border-gray-300 transition-all rounded-md bg-white p-1 px-1 hover:bg-blue-50"
                >
                    <span className="mb-1 flex text-blue-500">
                        <LiaHandshakeSolid />
                    </span>
                    <p className="text-xs text-gray-400 text-left">
                        Conserving Arctic biodiversity together
                    </p>
                </button>
                <button
                    type="button"
                    onClick={() =>
                        handleCreateChat('Nurturing nature-based solutions')
                    }
                    className="border border-gray-300 transition-all rounded-md bg-white p-1 px-1 hover:bg-blue-50"
                >
                    <span className="mb-1 flex text-blue-500">
                        <TbReportMoney />
                    </span>
                    <p className="text-xs text-gray-400 text-left">
                        Nurturing nature-based solutions
                    </p>
                </button>
            </div>
        </form>
    )
}
