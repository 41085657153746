import React, { ReactNode } from 'react'
import {
    Control,
    FieldValues,
    RegisterOptions,
    useController,
} from 'react-hook-form'
import cn from 'classnames'

export const classes: {
    base: string
    disabled: string
    error: string
    valid: string
    icons: string
    iconSpace: string
} = {
    base: 'flex-grow p-2 border border-gray-300 rounded-md min-h-[40px] max-h-[120px] resize-y font-inherit placeholder:font-[300]',
    disabled:
        'opacity-80 cursor-not-allowed pointer-events-none disabled-input-bg',
    error: '!border-[#ea5455]',
    valid: '!border-[#28c76f]',
    icons: 'absolute flex gap-0.5 right-2 top-[50%] translate-y-[-50%]',
    iconSpace: 'pr-7',
}

type TextInputProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    control: Control<FieldValues, string>
    name: string
    label?: string
    callBack?: () => void
    className?: string
    rules?: RegisterOptions
    icon?: ReactNode
    symbol?: string
    patternRule?: RegExp
    isFocus?: boolean
    isDebounce?: boolean
    disabled?: boolean
}

const TextArea = ({
    control,
    callBack,
    name,
    rules,
    defaultValue,
    className,
    patternRule,
    icon,
    isFocus,
    isDebounce,
    disabled,
    ...rest
}: TextInputProps) => {
    const {
        field,
        fieldState: { error, invalid, isDirty },
    } = useController({
        control,
        name,
        rules,
        defaultValue,
    })

    return (
        <div className="flex flex-col ">
            <div className="flex w-full relative">
                {icon && <div>{icon}</div>}
                <textarea
                    rows={5}
                    className={cn(
                        classes.base,
                        {
                            [classes.error]: error,
                            [classes.disabled]: disabled,

                            [classes.valid]:
                                !invalid && field.value && isFocus && isDirty,
                        },
                        className
                    )}
                    name={field.name}
                    onChange={
                        field.onChange as React.ChangeEventHandler<HTMLTextAreaElement>
                    }
                    value={field.value}
                    id={name}
                    disabled={disabled}
                    {...rest}
                />
            </div>
            {!!error?.message && (
                <p className="mt-1 text-left text-xs leading-none text-red-600">
                    {error?.message}
                </p>
            )}
        </div>
    )
}

export default TextArea
