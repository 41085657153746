import React, { useState } from 'react'
import { Document, ZoomItem } from '../types'
import { IoMdClose } from 'react-icons/io'
import { AiOutlineFullscreen } from 'react-icons/ai'
import cx from 'classnames'
import ModalWrapper from './ModalWrapper/ModalWrapper'
import { getViewerLibraryPath } from '../utils/helpers'

interface PdfCardProps {
    document: Document
    zoomList: ZoomItem[]
    onToggleExpand: () => void
    tipsIsOpen: boolean
}

const PdfCard: React.FC<PdfCardProps> = ({
    document,
    zoomList,
    onToggleExpand,
    tipsIsOpen,
}) => {
    const [zoomItem, setZoomItem] = useState<ZoomItem | null>(null)
    const pdfPath = document.highlighted_pdf_path || document.pdf_path
    const viewerUrl = getViewerLibraryPath(pdfPath)
    return (
        <>
            {zoomItem && (
                <ModalWrapper>
                    <div
                        style={{
                            backgroundColor: '#38383d',
                        }}
                        className="relative w-[70vw] h-[95vh] p-8 rounded"
                    >
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                setZoomItem(null)
                            }}
                            className="absolute right-2 top-2 border border-white bg-white rounded text-xl"
                        >
                            <IoMdClose />
                        </button>
                        <iframe
                            id={zoomItem.id}
                            className="w-full h-full"
                            src={zoomItem.viewerUrl}
                        ></iframe>
                    </div>
                </ModalWrapper>
            )}
            <div
                className={cx(
                    'flex-grow shrink-0 border-gray-200 border flex-col bg-white text-sm rounded-lg p-4 transition-all duration-300 ease-in-out cursor-pointer select-none relative align-top whitespace-normal hover:bg-blue-50 hover:border-blue-50',
                    {
                        'border-blue-50 !bg-blue-50': !!zoomList.find(
                            (item) =>
                                item.id === document.title.split(' ').join('')
                        ),
                    }
                )}
                onClick={onToggleExpand}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center ">
                        {zoomList.map((zoom, idx) => {
                            if (
                                zoom.id === document.title.split(' ').join('')
                            ) {
                                return (
                                    <div
                                        key={idx}
                                        className="border mr-1 flex items-center justify-center border-blue-500 text-blue-500 font-bold rounded-[100%] leading-3 h-4 w-4 text-[10px]"
                                    >
                                        {idx + 1}
                                    </div>
                                )
                            }
                            return null
                        })}

                        <h3 className="mt-0 cursor-pointer text-blue-500">
                            {document.title || `Document ${document.title}`}
                        </h3>
                    </div>

                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            const id = (document.title || '')
                                .split(' ')
                                .join('')
                            setZoomItem({ id, viewerUrl })
                        }}
                        className="ml-4 border border-gray-300 rounded text-xl text-gray-500"
                    >
                        <AiOutlineFullscreen />
                    </button>
                </div>

                <div className="document-details">
                    {(tipsIsOpen || !zoomList.length) && (
                        <p>
                            <strong>Year:</strong> {document.year || 'N/A'}
                        </p>
                    )}
                    {(tipsIsOpen || !zoomList.length) && (
                        <p>
                            <strong>Status:</strong> {document.status || 'N/A'}
                        </p>
                    )}
                    {(tipsIsOpen || !zoomList.length) && (
                        <p>
                            <strong>Document Type:</strong>{' '}
                            {document.document_type || 'N/A'}
                        </p>
                    )}{' '}
                    {(tipsIsOpen || !zoomList.length) && (
                        <p>
                            <strong>Geographic Scope:</strong>{' '}
                            {document.geographic_scope
                                ? document.geographic_scope.join(', ')
                                : 'N/A'}
                        </p>
                    )}
                    {(tipsIsOpen || !zoomList.length) && (
                        <p>
                            <strong>Conference:</strong>{' '}
                            {document.conference || 'N/A'}
                        </p>
                    )}
                </div>
                {/* PDF preview */}
                {/* <iframe
                    className="w-full aspect-square mt-3"
                    src={viewerUrl}
                ></iframe> */}
            </div>
        </>
    )
}

export default PdfCard
