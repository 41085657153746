import React, { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import Input from './Input'
import { EMAIL_REGEXP } from '../utils/helpers'
import { login } from '../services/authService'

export default function LoginForm() {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const { control, handleSubmit } = useForm<FieldValues>({
        mode: 'onChange',
    })

    const loginSubmit = async (values: FieldValues) => {
        setLoading(true)
        try {
            await login(values.email, values.password)
        } catch (e: any) {
            setError(e.message || 'Login failed. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(loginSubmit)} className="relative">
            <div className="mb-4">
                <Input
                    control={control}
                    name="email"
                    placeholder="email"
                    rules={{
                        required: {
                            value: true,
                            message: 'Enter an email address',
                        },
                        pattern: {
                            value: EMAIL_REGEXP,
                            message: 'Enter a valid email address',
                        },
                    }}
                />
            </div>
            <div className="mb-8">
                <Input
                    control={control}
                    name="password"
                    placeholder="password"
                    type="password"
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required',
                        },
                    }}
                />
            </div>
            <button
                disabled={loading}
                type="submit"
                className="px-4 py-2 bg-blue-500 w-full text-white rounded-md transition-colors duration-200 hover:bg-blue-600 disabled:opacity-30"
            >
                Log in
            </button>

            {error && <p className="text-red-600 text-center mt-3">{error}</p>}
        </form>
    )
}
