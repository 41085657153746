import makeRequest from '../make-request'
import { ChatItem, ChatRoom, Metadata } from '../../types'

export const getChatMessages = async (sessionId: string | null) => {
    return await makeRequest<ChatItem[]>({
        url: `/api/sessions/${sessionId}/messages`,
    })
}

export const getChatRooms = async (userId: string | null) => {
    return await makeRequest<ChatRoom[]>({
        url: `/api/users/${userId}/sessions`,
    })
}

export const getFilters = async () => {
    return await makeRequest<Metadata>({
        url: `/api/metadata`,
    })
}
