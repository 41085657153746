'use client'

import React, { ReactNode } from 'react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

type Props = { children: ReactNode }

function Providers({ children }: Props) {
    const [client] = React.useState(
        new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false, // default: true
                },
            },
        })
    )

    return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

export default Providers
